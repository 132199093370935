<!--
 * @Descripttion: 项目信息中心
 * @Author: chenjie
 * @Date: 2021-10-28 13:50:14
 * @LastEditors: chenjie
 * @LastEditTime: 2022-01-04 15:21:48
-->

<template>
  <div class="gateway zy-brand">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="zy-brand-banner">
      <img :src="bannerUrl" alt />
    </div>
    <!-- 项目信息数据列表 -->
    <div v-show="!showDetailArtice" class="famous-products gateway-module" >
      <div class="article-box">
        <el-collapse v-model="activeNames" :accordion="true" > 
          <div v-for="f in formData" :key="f.id" @click="detailArticle(f.id)">
            <el-collapse-item  :title="f.title" name="all" :disabled="true"  :id="f.id">
            <a href="#articleAnchor">
                <div class="article-subject">实施主体：{{f.subject}}</div>
                <div class="article-content" v-html="f.summary" /> 
              </a>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div>
        <div
          @click="loadMoreData"
          v-show="dataList.list&&dataList.list.length && dataList.totalPage > dataList.page"
          class="more-txt"
        >
          查看更多<i/>
        </div>
    </div>
 
    <div class="famous-products gateway-module" id="articleAnchor">
      <div class="article-box article-detail-box" v-show="showDetailArtice">
        <div class="article-detail-heard">
          <div class="article-detail-title">{{detailArticleData.title}}</div>
          <div class="article-detail-hint">
            <div class="adress">
              <img :src="adressPng">
              您的位置：产业园服务 >  项目信息中心  > 项目详情
            </div>
            <a 
              :href="articleAnchor"
              class="call-back" 
              @click="callback"
              @mouseenter="rootJiantou = jiantoulanPng"
              @mouseleave="rootJiantou = jiantouheiPng"
            >
              返回上一级
              <img :src="rootJiantou">
            </a>
          </div>
        </div>
        <!-- 文章主体 -->
        <el-form label-position="right" label-width="140px" :model="detailArticleData">
          <el-form-item label="「实施主体」"> 
            {{detailArticleData.subject}}
          </el-form-item>
          <el-form-item label="「项目投资额」"> 
            {{detailArticleData.investmentAmount}}
          </el-form-item>
          <el-form-item label="「项目进度」"> 
            {{detailArticleData.progress}}
          </el-form-item>
          <el-form-item label="「项目详情」"> 
            <div v-html="detailArticleData.content" />
          </el-form-item>
        </el-form>
      </div>
    </div>

    <gateway-footer />
    <ToTopperComponent />
  </div>
</template>
<script>
import { getBanner as initBannerImg, getInformationCenter, getInformationCenterDetail } from './modules/project-center'
import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'

import adressPng from './modules/adress.png' 
import jiantoulanPng from './modules/jiantoulan.png' 
import jiantouheiPng from './modules/jiantouhei.png' 

export default {
  name: 'zy-brand',
  data() {
    return {
      adressPng,
      jiantoulanPng,
      jiantouheiPng,
      rootJiantou:jiantouheiPng,
      activeNames: 'all',
      // 背景图片Url
      bannerUrl:'',
      formData:[],
      dataList:{},
      detailArticleData:{},
      // 参数
      pageParams:{
        "param": {
          "status": 1
        },
        "pageSize": 7,
        "page": 1,
        "orders": {
          createTime: "desc"
        }
      },
      
      showDetailArtice: false, // 切换列表或者明细
      articleAnchor:'' // 动态锚点
    }
  },
  // 数据监听
  watch: {},
  computed: {},
  mounted() {
    this.getBannerimg()
    this.handleInformationCenter(this.pageParams)
    
  },
  methods: {
    // 初始化背景图片
    async getBannerimg() {
      let res = await initBannerImg()
      let { data } = res
      this.bannerUrl=data[0].url
    },
    // 查看更多
    loadMoreData(){
      this.pageParams.page++
      this.handleInformationCenter(this.pageParams)
    },
    // 项目信息数据
    handleInformationCenter(params){
      getInformationCenter(params).then(res =>{
        this.formData = [...this.formData, ...res.data.list]
        this.dataList = res.data
        if(res.data.totalCount < params.pageSize){
          this.pageParams.page = 1
        }
      })
    },
     detailArticle(id){
      this.articleAnchor =  '#' + id
      this.showDetailArtice = true
       getInformationCenterDetail(id).then( res => {
        this.detailArticleData = res.data
      })
    },
    callback(){
      this.showDetailArtice = false
      this.rootJiantou = this.jiantouheiPng
    }
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    ToTopperComponent,
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  }
}
</script>
<style lang="less" scoped>
@import './project-center.less';
</style>
